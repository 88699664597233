
body {
  background-color: #f0f0f7 !important;
}

 .modal-body {
  overflow-x: auto;
  max-height: 500px;
  overflow-y: auto;
}
Table {
  overflow-x: auto; 
}