.set-navigation-buttons-background {
  position: relative;
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 300px; /* Initial width */
  background: #4a558a;
  height: 100vh;
  /* padding: 1rem; */
  z-index: 1;
  transition: width 0.3s ease; /* Transition for width change */
}

/* Update styles for sidebar buttons */
.set-navigation-buttons {
  width: 100%;
  height: 3rem;
  margin: 1rem 0;
  background-color: #111d2c;
  border: none;
  color: white;
  border-radius: 0.5rem;
  font-size: 1.5rem;
  display: flex !important;
  align-items: center !important;
  gap: 20px !important;
  padding-left: 18px;
  cursor: pointer;
}

.set-navigation-buttons-active {
  width: 100%;
  height: 3rem;
  margin: 1rem 0;
  background-color: rgb(45, 57, 75);
  border: none;
  color: white;
  border-radius: 0.5rem;
  font-size: 1.5rem;
  display: flex !important;
  align-items: center !important;
  gap: 20px !important;
  padding-left: 18px;
  cursor: pointer;
}

/* Update styles for hover button */
.span-hover-button {
  position: absolute;
  top: 40px;
  right: -17px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background-color: lightgrey;
  color: black;
  border-radius: 50%;
  z-index: 999;
  transition: right 0.3s ease;
}

.sidebar-closed .span-hover-button {
  right: 0;
}

.set-border-line {
  border-bottom: 1px solid darkgrey;
}
