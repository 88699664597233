@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&family=Montserrat:wght@400;600;700&display=swap');
:root {
  --primary-color: #E83D56;
  --bs-link-color: #E83D56;
  --bs-link-hover-color: #E83D56;
  --bs-btn-active-bg: #E83D56;
  --bs-btn-disabled-bg-2: #e47081;
}
body, p, h1, h2, h3, h4, h5, h6, a, span, li, ul, ol, button, input, select, textarea, label {
  font-family: 'Poppins', sans-serif;
}
.mont-font h1,
.mont-font h2,
.mont-font h3,
.mont-font h4,
.mont-font h5,
.mont-font h6,
.mont-font p,
.mont-font a,
.mont-font span,
.mont-font li,
.mont-font ul,
.mont-font ol,
.mont-font button,
.mont-font input,
.mont-font select,
.mont-font textarea,
.mont-font label {
  font-family: "Montserrat", sans-serif;
}
.thread-list-item p {
  font-size: 14px;
}
.btn-primary {
  background-color: var(--primary-color);
  border:none;
  outline:none;
}
.btn-primary:hover,.btn-primary:active {
  background-color: var(--primary-color);
}
.btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active {
  background-color: var(--primary-color);
}
.btn:disabled {
  background-color: var(--bs-btn-disabled-bg-2);
}

.gray_bg {
  background-color: #F0F0F7;
}
.form-logo {
  width:90px;
}
.sign_form {
  max-width:300px;
  width:100%;
  margin:0 auto;
  background: #fff;
  margin:50px auto;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
}
.form-control {
  height: 46px;
  border: 1px solid rgba(102, 102, 102, 0.35);
  border-radius: 12px;
  transition: border-color 0.5s ease;
  outline: none !important;
  box-shadow: none !important;
}
.form-control.error {
  border-color:#E83D56;
}
.sign_form .form-group {
  margin-bottom:15px;
}
.sign_form .btn {
  width:100%;
  height:46px;
  margin-top:15px;
}
.forgot_btn {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height */
  text-align: right;
  text-decoration-line: underline;
  float: right;
  color: #111111;
}
.rememberLabel {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height */

  color: #333333;
}
.mfa_form {
  max-width:400px;
  width:100%;
  margin:0 auto;
  background: #fff;
  margin:50px auto;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
}
.mfa_form .btn {
  width:100%;
  margin-bottom:15px;
}
.mfa_form .form-control {
  margin-bottom:15px;
}
.mfa_form .form-logo {
  margin-bottom:15px;
}
.newpass_form .form-logo {
  margin-bottom:15px;
}
.ept-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  min-height:77px;
  /* background: #fff url(./images/header_bg.png) center center no-repeat; */
  background: #fff;
  background-size: cover;
}
.navbar-brand img {
  width:120px;
}
.navbar-brand span {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 22.4px;
}
.sub_nav {
  display: flex;
  align-items: center;
}
.profile-avatar {
  width:40px;
  height:40px;
  border-radius: 50%;
  margin-right: 10px;
}
.forum-select {
  position: relative;
  width: 320px;
  height: 39px;
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 5px;

  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  appearance: none;
  background: #fff url(./images/down_icon_forum.svg) right 10px top 15px no-repeat;
  padding-left: 20px;
  padding-right: 40px;
  text-overflow: ellipsis;
  margin-left: 0px;
  margin-top:20px;
  outline: none !important;
}
.dropdown-toggle::after {
  content: url(./images/down_icon_forum.svg);
  border:none;
  vertical-align: middle;
  margin-top:-2px;
}
.filters-wrap {
  margin-top:35px;
}
.filters {
  list-style:none;
  background: #FFFFFF;
  box-shadow: 2px 1px 15px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 15px;
  /* display: flex;
  align-items: left;
  justify-content: left; */
  margin-top:10px;
}
.filters li {
  width: 100%;
  margin-right:10px;
  margin-bottom:10px;
}
.filters li select {
  width: 100%;
}

.filterHeading {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.05em;
  text-transform: capitalize;
}
.filterHeading:before {
  content: url(./images/filters.svg);
  margin-right:10px;
  vertical-align: -3px;
}
.filters li .btn.btn-secondary{
  width:100%;
  height: 38px;
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  background: #fff;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color:hsl(0, 0%, 50%);
}
.filters li select {
  height: 38px;
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  background: #fff;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color:hsl(0, 0%, 50%);
  padding-left: 8px;
  outline: none !important;
}
.filters li select#days {
  appearance: none;
  background:url(./images/blue_dropdown.svg) right 9px top 15px no-repeat;
}
.css-1hb7zxy-IndicatorsContainer .css-1xc3v61-indicatorContainer:nth-child(n+2) svg {
  display: none;
}
.css-1hb7zxy-IndicatorsContainer .css-1xc3v61-indicatorContainer:nth-child(n+2) {
  background:url(./images/blue_dropdown.svg) center center no-repeat;
  width: 32px;
}
.css-1u9des2-indicatorSeparator {
  opacity: 0;
}
.filters li .btn-check:checked+.btn, 
.filters li .btn.active, 
.filters li .btn.show, 
.filters li .btn:first-child:active, 
.filters li :not(.btn-check)+.btn:active {
  background: #f8f8f8;
  color:#000;
}

.filters li label {
  font-weight: 600;
  font-size: 14px;
  margin-bottom:5px;
}
.react-date-picker__wrapper {
  height: 38px;
  border: 1px solid rgba(0, 0, 0, 0.16) !important;
  border-radius: 5px;
  background: #fff;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color:hsl(0, 0%, 20%);
}
.react-date-picker__calendar-button svg{
  display:none !important;
}
.react-date-picker__calendar-button {
  background: url(./images/calendar_icon.svg) center center no-repeat !important;
  width: 40px;
}



.pagination-wrap {
  position: relative;
  display: flex;
  align-items: right;
  justify-content: right;
}
.threads-count {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 14px;
  line-height: 32px;
  color: #000000;
  width:160px;
}
.dash-pagination {
  list-style: none;
  padding:0;
  display: flex;
  align-items: right;
  justify-content: right;
}
.dash-pagination a {
  display: block;
  width: 32px;
  min-width: 32px;
  height: 32px;
  line-height: 30px !important;
  margin-left: 6px;
  background: #053F98;
  border-radius: 5px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color:#000;
  text-decoration: none;
}
.dash-pagination a.prev {
  background: #053F98 url(./images/pagination_left_white.svg) center center no-repeat;
}
.dash-pagination a.next {
  background: #053F98 url(./images/pagination_right_white.svg) center center no-repeat;
}

.dash-pagination a.prev.disabled,
.dash-pagination a.next.disabled {
  background-color:#919EAB;
}

.dash-pagination select {
  width:100%;
  height: 32px;
  border-radius: 5px;
  background: #fff;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  color:#000;
  margin-left:6px;
  padding-left:20px;
  padding-right:40px;
  appearance: none;
  background:#fff url(./images/down_icon_forum.svg) right 10px top 13px no-repeat;
  outline:none !important;
  text-overflow: ellipsis;
  border: none;
  box-shadow: 2px 1px 45px rgba(0, 0, 0, 0.1);
}
.dash-pagination select option:disabled {
  color:red !important;
  font-size:12px;
}

.search-form .form-control {
  width: 267px;
  height: 40px;
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 100px;
  background: url(./images/search.svg) right 15px top 7px no-repeat;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  padding-left: 15px;
  padding-right: 40px;
}
.dash-left {
  margin-top:20px;
}
.search-and-list-wrap {
  margin-top:20px;
}
a.search-btn {
  background: url(./images/search.svg) center center no-repeat;
  display: block;
  width: 32px;
  min-width: 32px;
  height: 32px;
  line-height: 30px !important;
  margin-left: 6px;
  border-color:transparent ;
  border-radius: 4px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color:#000;
  text-decoration: none;
}
.search-box-2 {
  position:absolute;
  left:0;
  top:0;
  background: #f5f5f5;
  width:100%;
  z-index: 100;
  display: none;
}
.search-box-2-inner {
  display: flex;
  align-items: left;
  justify-content: left;
}
.search-box-2 .form-control {
  width: 100%;
  height: 32px;
  border: none;
  border-radius: 5px;
  background: #fff url(./images/search.svg) right 10px top 3px no-repeat;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  padding-left: 15px;
  padding-right: 40px;
  box-shadow: 2px 1px 45px rgba(0, 0, 0, 0.1);
}
.search-content {
  width:100%;
  max-height:200px;
  overflow-y: auto;
  overflow-x: hidden;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  background:#fff;
  position: absolute;
  top:44px;
  left: 0;
  border-radius: 10px;
  padding-top:15px;
  padding-bottom: 15px;
  display: none;
}
.search-content ul {
  padding:0;
  margin:0;
}
.search-content li {
  list-style: none;
  padding:0;
  margin:0;
  text-align: center;
}
.search-content li a {
  display: block;
  width:100%;
  font-weight: 300;
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 0.05em;
  color: #000000;
  text-decoration: none !important;
  background: #ffffff;
  transition: background 0.3s ease-in-out;
  border:none !important;
  border-bottom:  1px solid rgba(0, 0, 0, 0.08) !important;
  text-align: left;
  margin: 0;
  padding-left: 30px;
  padding-right: 30px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  border-radius: 0;
}
.search-content li a:hover {
  background: #f4f4f4;
}
.dash-pagination a.close-search-btn {
  width:80px;
  border:none;
  text-align: left;
  line-height:33px !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-top:0;
  background-color: transparent;
}
.dash-pagination a.close-search-btn img {
  margin-right:10px;
  vertical-align: -2px;
}
/* Threads css */
.threads-list {
  margin-top:-3px;
}
.thread-box {
  position: relative;
  width: 100%;
  min-height: 100px;
  background: #FFFFFF;
  /* box-shadow: 2px 1px 15px rgba(0, 0, 0, 0.1); */
  border-radius: 5px;
  padding:20px;
  margin-bottom:20px;
  transition: border 0.3s ease;
}
.thread-box.thread-box-child {
  padding-left: 40px;
  padding-bottom: 10px;
  border-left: 5px solid rgba(0, 0, 0, 0.5);
}
.thread-box.thread-box-child.thread-box-solved {
  border-left: 5px solid #55E77D;
}
.thread-box.thread-box-child.thread-box-ept-res {
  border-right: 5px solid #053F98;
}
.thread-box.thread-box-matched-post-id .thread-matched-status {
  opacity: 1;
}


.matched-response-head {
  padding: 6px 10px 5px 9px;
  background-color: #96D4E5;
  border-radius: 4px;
  color:#002116;
  font-size:12px;
  height: 30px;
  margin-top: 5px;
  display: none;
}
.response-heading.response-box-left-orange .matched-response-head {
  display: block;
}
.response-matched {
  border-left: 5px solid #053F98;
}
.thread-box p, .response-box p,
.thread-box div, .response-box div {
  font-size:14px;
  font-family: 'Poppins', sans-serif;
  line-height: 18px;
}
.thread-box p, .response-box p {
  margin-bottom:10px;
}
.thread-box li, .response-box li {
  font-size:14px;
  font-family: 'Poppins', sans-serif;
  line-height: 18px;
  margin-bottom:10px;
}
.thread-box a, .response-box a {
  color:#154B9F;
}

.thread-author-name {
  font-size: 13px;
  line-height: 15px;
}
.thread-date {
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.05em;
  color: #808080;
  margin-top:3px;
}
.thread-author {
  display: block;
}
.thread-author span {
  display: inline-block;
  margin-right:10px;
}
.thread-author-bottom {
  border-top:solid #ccc 1px;
  padding-top:8px;
}
.thread-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  margin:9px 0;
  position: relative;
  width: 100%;
}

.thread-body {
  width: 100%;
  position: relative;
  overflow: hidden;
  padding-bottom:25px;
}

.thread-like-btn::before {
  content:url(./images/like_btn.svg);
  margin-right: 5px;
  vertical-align: -1px;
}

.thread-like-btn {
  position: absolute;
  bottom:21px;
  right:30px;
  font-size: 12px;
  line-height: 14px;
  color:#000 !important;
  text-decoration: none;
  cursor: default !important;
}

.ext_link:before {
  content: url(./images/link.svg);
  vertical-align: -1px;
  margin-right: 5px;
}
.ext_link {
  background: #fff;
  border-radius: 5px;
  display: block;
  position: absolute;
  right:80px;
  bottom:16px;
  width:162px;
  text-align: center;
  font-size: 12px;
  line-height: 14px;
  color: #154B9F !important;
  line-height: 24px;
  padding-top:2px;
  text-decoration: none;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  border:solid 1px #154B9F;
}
.ext_link:hover {
  opacity: 0.8;
}
.thread-tags {
  display: flex;
  align-items: left;
  flex-direction: row;
  margin-top:10px;
}
.thread-tags a {
  display: block;
  padding: 5px 10px;
  height: 22px;
  background: #EAEAEA;
  border-radius: 5px;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.05em;
  color: #808080;
  margin-right:10px;
  text-decoration: none;
  margin-bottom:10px;
}
.tags-heading {
  font-size:14px;
  margin-right:5px;
}

.response-dash-wrap {
  margin-top:0px;
}
.response-wrapper {
  position: relative;
  width: 100%;
  min-height: 100px;
  background: #FFFFFF;
  border-radius: 5px;
  margin-bottom:20px;
  overflow: hidden;
  margin-top: 40px;
}
.no-response-found {
  position: relative;
  width: 100%;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.17);
  border-radius: 10px;
  margin-bottom:20px;
  color:red;
  text-align: center;
  padding:20px;
}

.response-main-head:before {
  content: url(./images/new_res_icon.svg);
  display: inline-block;
  vertical-align: -6px;
  margin-right: 10px;
}
.response-main-head{
  font-size: 16px;
  font-weight: 600;
  margin-top:9px;
}

.response-heading {
  padding:16px 20px 0px 25px;
  display: flex;
  justify-content: space-between;
}
.response-heading span {
  font-size: 14px;
  font-weight: 600;
  margin-top:2px;
  color:#053F98;
  line-height: 21px;
  margin-top:10px;
  opacity: 0;
}
.response-heading span:before {
  content:url(./images/response_studio.svg);
  margin-right: 7px;
  vertical-align: -5px;
}
.response-wrapper img {
  max-width: 100%;
  margin:10px auto;
}
.response-box {
  padding:14px 20px 24px 25px;
  overflow-wrap: break-word;
}
.response-box-new {
  border-radius: 10px;
  background: #F1F7FF;
  padding: 30px 15px 20px;
  position: relative;
  /* max-height: 300px;
  overflow-y: auto; */
}

.response-icon-wrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  display: none;
}
.response-icon-wrap.response-icon-wrap-single {
  justify-content: right;
}
.response-icon-wrap-2 {
  text-align: center;
  border-bottom: solid 1px rgba(0, 0, 0, 0.10);
  padding-bottom:24px;
  margin-bottom:20px;
}
.response-icon {
  width:131px;
  height:35px;
  cursor: pointer;
  position: relative;
  border-radius: 5px;
  border: 0.5px solid #053F98;
  background: #EBF3FF;
  color: #154B9F;
  font-size: 12px !important;
  font-weight: 500;
  text-align: center;
  line-height: 35px !important;
  margin-top:20px;
  display: inline-block;
  
}
.response-icon:before {
  content:url(./images/copy_icon.svg);
  vertical-align: -4px;
  margin-right: 5px;
}
.copySuccess {
  padding:2px 5px;
  background: #EBF3FF;
  border-radius: 5px;
  position: absolute;
  text-align: center;
  font-size: 12px;
  line-height: 28px !important;
  color: #154B9F !important;
  top: 0px;
  left: 0;
  width: 100%;
  display: none;
}
.generator-wrap {
  margin-bottom:10px;
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  width:143px;
  text-align: center;
  line-height: 27px !important;
  font-weight: 600;
  font-size: 14px;
  
}

 /* Loader */

 .loader {
  border: 10px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid red;
  width: 60px;
  height: 60px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}
.loader.loader-sm {
  width:32px;
  height:32px;
  border-width: 5px;
}

.loading-threads {
  display: flex;
  align-items: right;
  justify-content: right;
  font-size:14px;
}
.loading-threads .loader {
  margin-left: 5px;
  vertical-align: middle;
}
.dash-left .loading-threads {
  align-items: left;
  justify-content: center;
  margin-top:30px;
}
.login-loading.loading-threads, 
.loading-threads.loading-center {
  align-items: center;
  justify-content: center;
  margin-top:0px;
}
.main-loader {
  position: fixed;
  width: 100%;
  height: 100%;
  top:0;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main-loader p {
  display: block;
}

.no-thread-found-error {
  text-align: center;
  color:red;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* ranking form */
.rankingForm {
  display: none;
  position: absolute;
  width: 260px;
  background: #FFFFFF;
  box-shadow: 0px 4px 21px 5px rgba(0, 0, 0, 0.09);
  border-radius: 10px;
  bottom:70px;
  right:0;
  padding-bottom:15px;
}

.rankingForm label {
  border-bottom:1px solid rgba(0, 0, 0, 0.08);
  width:100%;
  padding:5px;
  position: relative;
}
.rankingForm textarea {
  width:100%;
  background: #F3F3F3;
  border-radius: 10px;
  border:none;
  outline:none;
  font-size: 12px;
  line-height: 25px;
  letter-spacing: 0.05em;
  color: #000000;
  padding:10px;
  min-height: 83px;
}
.text-area-wrap {
  padding:10px;
}
.response-pagination {
  display: flex;
}
.response-pagination a {
  display: block;
  width: 32px;
  min-width: 32px;
  height: 32px;
  line-height: 30px !important;
  margin-left: 6px;
  background: #053F98;
  border: none;
  border-radius: 5px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color:#000;
  text-decoration: none;
  margin-top:4px;
}
.response-pagination a.response-prev {
  background:#053F98 url(./images/pagination_left_white.svg) center center no-repeat;
}
.response-pagination a.response-next {
  background:#053F98 url(./images/pagination_right_white.svg) center center no-repeat;
}

.response-pagination a.response-prev.disabled,
.response-pagination a.response-next.disabled {
  background-color:#919EAB;
}

.response-form-wrap {
  display: flex;
  align-items: right;
  justify-content: right;
  flex-wrap: wrap;
  margin-top:10px;
}

.response-wrapper .like-dislike-wrap {
  position: relative;
  background: rgba(217, 217, 217, 0.32);
  border-radius: 100px;
  width:109px;
  height:28px;
  display: flex;
  align-items: left;
  justify-content: left;
}
.like-dislike-wrap a {
  width: 50%;
  height:28px;
  position: relative;
}
.like-dislike-wrap a.like-btn {
  background: url(./images/like_btn.svg) center center no-repeat;
}
.like-dislike-wrap a.like-btn.active {
  background: url(./images/like_btn_active.svg) center center no-repeat;
}
.like-dislike-wrap a.dislike-btn {
  background: url(./images/dislike_btn.svg) center center no-repeat;
}
.like-dislike-wrap a.dislike-btn.active {
  background: url(./images/dislike_btn_active.svg) center center no-repeat;
}
.like-dislike-wrap a.like-btn:after {
  content:"";
  border: 1px solid rgba(0, 0, 0, 0.1);
  height:80%;
  top:10%;
  right:-1px;
  position: absolute;
}

/* Checkbox container styles */
.custom-response-checkbox {
  display: flex;
  align-items: center;
}

/* Checkbox label styles */
.custom-response-checkbox .checkbox-label {
  margin-left: 10px;
}

/* Checkbox input styles */
.custom-response-checkbox input[type="checkbox"] {
  opacity: 0; /* Hide the actual checkbox */
  position: absolute;
}

/* Checkmark styles */
.custom-response-checkbox .checkmark {
  display: inline-block;
  width: 23.5px;
  height: 23.41px;
  background: #FFFFFF;
  opacity: 0.5;
  border: 1px solid #000000;
  border-radius: 4px;
  position: absolute;
  right: 20px;
  top: 6px;
}

/* Checkmark pseudo-element styles */
.custom-response-checkbox .checkmark::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background:url(./images/checkbox.svg) center center no-repeat;
  opacity: 0;
}

/* Checkmark hover styles */
.custom-response-checkbox .checkmark:hover {
  border-color: #999;
}
.custom-response-checkbox input[type="checkbox"] + .checkmark:hover {
border: 1px solid #000000;
}
.custom-response-checkbox input[type="checkbox"]:checked + .checkmark {
  background: #053F98;
  border: 1px solid #053F98;
  opacity: 1;
}
/* Checked checkmark styles */
.custom-response-checkbox input[type="checkbox"]:checked + .checkmark::after {
  opacity: 1;
}
.response-form-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.cancel-btn {
  background: #EAEAEA !important;
  border-radius: 5px;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: #808080;
}
.submit-btn {
  border-radius: 5px;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color:#fff !important;
  background: #E83D56 !important;
  padding-left: 30px;
  padding-right: 30px;
}
.break {
  flex-basis: 100%;
  height: 0;
}
.response-success {
  font-size: 14px;
  color:green;
  margin-top:10px;
}

.forum-select,
.dash-pagination select,
.filters li select, 
.css-1jqq78o-placeholder,
.filters li .btn.btn-secondary,
.search-box-2 .form-control {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size:14px;
  color:#000 !important;
}

/* footer  */

footer {
  width:100%;
  background: #000000;
  padding:10px 0;
}

.copyright-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
  margin-top:5px;
  margin-bottom:0;
}

.footer-nav {
  display: flex;
  align-items: right;
  justify-content: right;
  flex-wrap: wrap;
  margin:0;
  padding: 0;
  list-style: none;
}
.footer-nav li {
  margin-left:40px;
}
.footer-nav li a {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
  text-decoration: none;
}

.dashboard-container {
  min-height:calc(100vh - 256px);
}

pre {
	width: 100%;
	padding: 15px;
	margin: 0;
	overflow: auto;
	overflow-y: hidden;
	font-size: 12px;
	line-height: 20px;
	background: #eaeaea;
	border: 1px solid #eee;
  counter-reset: line;
  border-radius: 10px;
  margin-bottom:10px;
}
pre code {
	padding: 10px;
	color: #333;
}

pre span {
  display: block;
  line-height: 1.5rem;
}
pre span:before {
  counter-increment: line;
  content: counter(line);
  display: inline-block;
  border-right: 1px solid #ddd;
  padding: 0 .5em;
  margin-right: .5em;
  color: #888
}

/* User management page */

.settings-header h1 {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  letter-spacing: 0.3px;
  color: #000;
  margin:20px 0;
}

.user-management-tabs {
  margin-top:10px;
  display: none;
}

.user-management-tabs.nav-tabs .nav-link {
  font-family: 'Poppins', sans-serif;
  border:none;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.3px;
  color: #000000;
  opacity: 0.3;
}

.user-management-tabs.nav-tabs .nav-item.show .nav-link, 
.user-management-tabs.nav-tabs .nav-link.active {
  font-weight: 800;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.3px;
  color: #000000;
  border-bottom: 2px solid #053F98;
  opacity: 1;
  background-color: transparent;
}

.account-tab-box {
  background: #FFFFFF;
  box-shadow: 0px 4px 100px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  padding:30px;
  margin-top:30px;
}

.account-nav {
  display: flex;
  justify-content: right;
}

.account-nav a {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #053F98 !important;
}
.account-nav a.edit_link:before {
  content: url(./images/edit_icon.svg);
  display: inline-block;
  vertical-align: -5px;
  margin-right: 10px;
}
.account-nav a.edit_link {
  margin-left: 25px;
}
.account-nav a.delete_account {
  color: #455A64 !important;
  opacity: 0.5;
  text-decoration: none;
}

.account-form-wrap {
  display: flex;
  justify-content: left;
  margin-bottom:20px;
}
.account-form .form-group {
  margin-right:25px;
}
.account-form .form-control {
  width:260px;
  /* background:rgba(209, 209, 209, 0.2); */
  background:#fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  /* product drop shadow */
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.account-form label {
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin-right: 10px;
}

.account-form a.account-form-btn {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  /* Blue */
  color: #053F98 !important;
  display: inline-block;
  margin-top:54px;
  margin-right:25px;
  text-decoration: none;
}
.susbcription-type-wrap {
  display: flex;
  align-items: center;
  justify-content: left;
}
.susbcription-type-wrap > * {
  margin-right: 15px;
}
.td-relative {
  position: relative;
}
.td-relative.custom-response-checkbox .checkmark {
  top:0;
  right:auto;
  left:0;
}
.table-header,
.table-footer {
  padding: 0 20px;
}
.header-button-adjust {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding:15px 20px 0;
}
.users-table th {
  cursor: pointer;
}
.settings-tabs {
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 4px 100px 0px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  margin-bottom: 30px;
}
.account-form h5 {
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  margin: 15px 0 20px;
}
.table-header h5 {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.3px;
  color: #053F98;
}

.account-form-btn-upgrade {
  background: #E83D56;
  border: 1px solid #E83D56;
  border-radius: 10px;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #FFFFFF !important;
  text-decoration: none;
  padding:  10px 20px;
  margin: 10px 0;
  display: inline-block;
}
.account-name {
  display: flex;
  align-items: start;
  justify-content: left;
}
.account-name-title {
  min-height: 35px;
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-right:10px;
}
.edit-account-name {
  height: 30px;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
.edit-account-name-placeholder {
  color:#a9a9a9;
}
.edit-account-name:after {
  content: url(./images/edit_icon.svg);
  display: inline-block;
  vertical-align: -5px;
  margin-left: 10px;
  cursor: pointer;
}

.account-name-input {
  border: solid 1px #ccc !important;
  outline: none !important;
  border-radius: 5px;
}

.table-box {
  background: #FFFFFF;
  box-shadow: 0px 4px 100px rgba(0, 0, 0, 0.08);
  /* border-radius: 10px; */
  margin-top:15px;
  /* padding-top:20px; */
  padding-bottom: 60px;
  width: 100%;
  overflow-x: auto;
}
.table-box a {
  color: #053F98;
  text-decoration: none;

}
.table-name-th,
.table-name-td {
  padding-left:40px !important;
}
.invite-user-table .request_btn {
  margin-top: 10px;
  display: inline-block;
}
.delete-user-btn {
  display: inline-block;
  margin-left: 10px;
  margin-top:2px;
}
.delete-user-btn:before {
  content: url(./images/delete_icon.svg);
}
.user-role-select {
  position: relative;
  border: none;
  border-radius: 5px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  appearance: none;
  background: url(./images/down_icon_forum.svg) right 0px top 7px no-repeat;
  padding-left: 0px;
  padding-right: 30px;
  text-overflow: ellipsis;
  margin-left: 0;
  outline: none !important;
}
.table-header {
  display: flex;
  justify-content: space-between;
}
.table-box thead {
  background: #F5F5F5;
  vertical-align: middle;
}
.table-box thead tr {
  border-top:solid #D1D1D1 1px;
}
.table-box th {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.1px;
  padding-top:15px;
  padding-bottom: 15px;
}
.table-box td {
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #252733;
  vertical-align: middle;
}
.invite-user-btn {
  font-family: "Montserrat", sans-serif;
  display: inline-block;
  background: #000;
  border-radius: 8px;
  padding:12px 34px 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #FFFFFF !important;
  text-decoration: none;
  margin-top:10px;
}
.invite-user-btn:before {
  content: url(./images/invite_icon.svg);
  display: inline-block;
  vertical-align: -4px;
  margin-right: 10px;
}
.users-info {
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.3px;
  color: rgba(0, 0, 0, 0.5);
  margin-top:25px;
}
.last_login {
  font-size: 10px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #252733;
  background: #FFF7DE;
  border-radius: 100px;
  padding:5px 10px;
}
.invite-user-box, .delete-user-modal {
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background:rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
}
.invite-user-box.show {
  display: flex;
}

.invite-user-box-content {
  background: #FFFFFF;
  border-radius: 10px;
  width:100%;
  max-width:500px;
  margin:0 auto;
  padding:20px;
}

.invite-user-box-header {
  display: flex;
  justify-content: left;
}

.invite-user-box-content h3 {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #000000; 
}

.close-invite-user-box {
  width:20px;
  height:22px;
  background: url(./images/back_btn.svg) left center no-repeat;
  display: inline-block;
}

.multiple-email-input {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start; /* Updated: align items to flex-start */
  padding: 5px;
  border: 1.5px solid #053F98;
  border-radius: 5px;
  min-height: 52px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #252733;
  margin-top: 15px;
}

.multiple-email-input input {
  flex-grow: 1; /* Added: allow the input to grow and fill remaining space */
  border: none;
  outline: none;
  height:44px;
  border-bottom: 1px solid transparent;
}
.multiple-email-input input.invalid {
  border-color: red; /* Added: red underline for invalid emails */
}

.email-tag {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  padding: 5px 10px;
  margin: 5px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 100px;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #252733;
}

.remove-button {
  margin-left: 5px;
  border: none;
  background: none;
  color: #888;
  cursor: pointer;
}

.notifycheckbox {
  margin:10px 0;
}
.notifycheckbox-label-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  margin-left: 10px;
}
.notifycheckbox-input {
  vertical-align: -2px;
}

.message-textarea-input {
  border: 1px solid rgba(0, 0, 0, 0.5) !important;
  border-radius: 5px;
  min-height: 177px !important;
  resize: none;
  outline: none;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
}

.invite-user-box-footer {
  display: flex;
  justify-content: right;
  margin-top:20px;
}
.invite-user-box-btn {
  display: inline-block;
}
.invite-user-box-btn.cancel,
.delete-user-modal-btn.cancel {
  font-size: 18px;
  line-height: 24px;
  color: #154B9F !important;
  margin-right:20px;
  margin-top:10px;
  text-decoration: none;
}
.invite-user-box-btn.done,
.delete-user-modal-btn.done {
  background: #E83D56;
  border-radius: 10px;
  font-size: 18px;
  line-height: 24px;
  color: #FFFFFF;
  text-decoration: none;
  padding:10px 20px;
  display: inline-block;
}
.invite-user-box-btn.done {
  background: #063f97;
}
.delete-user-modal-btn.update-btn {
  background: #063f97;
  border-radius: 10px;
  font-size: 18px;
  line-height: 24px;
  color: #fff !important;
  text-decoration: none;
  padding:10px 20px;
  margin-top:10px;
  margin-left: 15px;
  display: inline-block;
}
.invite-user-box-btn.done.disabled,
.delete-user-modal-btn.done.disabled,
.delete-user-modal-btn.update-btn.disabled {
  background:#ccc !important;
  cursor: default;
}

.delete-user-modal-footer {
  border-top:solid #ccc 1px;
  padding-top:15px;
  margin-top:20px;
}

.delete-user-modal {
  justify-content: center;
  text-align: center;
}
.delete-user-modal.show {
  display: flex;
}

.delete-user-modal-content {
  background: #FFFFFF;
  border-radius: 10px;
  width:100%;
  max-width:400px;
  margin:0 auto;
  padding:20px 20px 40px;
}

.delete-user-modal h5 {
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #252733;
  margin:20px auto 40px;
}
.error-message.error {
  color:red;
  text-align: center;
}
.request-model-content .input-group{
  justify-content: left;
  align-items: left;
  text-align: left;
  position: relative;
  margin-bottom:15px;
}
.request-model-content .input-group label {
  width: 100%;
}
.request-model-content .input-group .form-control {
  margin-top:10px;
}
.request-model-content .checkbox-label {
  margin-left:30px;
}
.request_btn {
  font-family: "Montserrat", sans-serif;
  background: #000 !important;
  color:#fff !important;
  font-size: 12px;
  font-weight: 600;
  text-decoration: none;
  opacity: 1;
  background: transparent;
  padding: 14px 34px;
  border-radius: 8px;
  border:solid 1px #000;
  transition: all 0.3s ease-in-out;
}
.request_btn:hover {
  background: #E2EEFF;
}
/* Theme Switcher  */
.theme-switcher {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.theme-switcher .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}

.theme-switcher .slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}

.theme-switcher input {
  opacity: 0;
  width: 0;
  height: 0;
}

.theme-switcher.dark .slider {
  background-color: #333;
}

.theme-switcher.dark .slider:before {
  transform: translateX(26px);
}

.thread-status-wrap {
  position: absolute;
  top:15px;
  right:15px;
  z-index: 5;
}
.thread-status-wrap > div {
  display: inline-block;
}
.thread-status {
  background: #053F98;
  border-radius: 4px;
  font-size: 12px !important;
  line-height: 12px !important;
  letter-spacing: 0.3px;
  color: #fff;
  padding:8px 10px 8px 9px;
  width: 75px;
  text-align: center;
  margin-left: 15px;
}
.thread-matched-status {
  background: #96D4E5;
  border-radius: 4px;
  font-size: 12px !important;
  line-height: 12px !important;
  letter-spacing: 0.3px;
  color: #002116;
  padding:8px 10px 8px 9px;
  width: 75px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.post-solved-status {
  background: #CCFFDA;
  border: 0.5px solid #04B87C;
  border-radius: 4px;
  font-size: 12px !important;
  line-height: 12px !important;
  letter-spacing: 0.3px;
  color: #002116;
  padding:7px 10px 5px;
  width: 165px;
  text-align: center;
  margin-bottom: 15px !important;
}

.hide-me {
  display: none;
}

.dashboard-header {
  background: url(./images/banner_2.png) no-repeat center center;
  min-height:163px;
  background-size: cover;
  padding:27px 30px;
}
.hero-date {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color:#fff;
  margin-bottom:10px;
  margin-top:15px;
}
.hero-text {
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0;
  color:#fff;
}
.hero-text.hero-text-center {
  margin-top:4px;
}

.generate-response {
  width:100%;
  border-radius: 5px;
  border: 0.5px solid #053F98;
  background: #EBF3FF;
  color: #154B9F;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  line-height: 38px;
  cursor: pointer;
}
.generate-response-head {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color:#000;
  margin-bottom:10px;
  margin-top:15px;
  text-align: center;
}
.generate-response-wrap {
  padding: 20px;
}

.response-generator-loader {
  text-align: center;
  font-size:14px;
  margin-top:10px;
  color:#053F98;
}
.response-generator-loader .loader {
  display: inline-block;
  margin-top:10px;
}

.threadlist-main-wrap {
  /* margin-top:30px; */
}
.thread-list-wrap {
  border-radius: 5px;
  background: #FFF;
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom:30px;
  min-height: 200px;
}
.thread-list-item {
  border-bottom:solid 1px rgba(0, 0, 0, 0.10);
  padding-top:30px;
  padding-bottom:30px;
  cursor: pointer;
  position: relative;
  display: block;
  color:#000 !important;
  text-decoration: none !important;
  user-select: none !important;
}
.thread-list-item .thread-author-name i {
  background:rgba(127, 150, 145, 0.5);
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.7px;
}
.thread-list-item .thread-date{
  color: #808080;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.6px;
}
.filtersV2-wrap .btn-group>.btn-group:not(:last-child)>.btn, 
.filtersV2-wrap .btn-group>.btn.dropdown-toggle-split:first-child, 
.filtersV2-wrap .btn-group>.btn:not(:last-child):not(.dropdown-toggle),
.filtersV2-wrap .btn-group>.btn-group:not(:first-child), 
.filtersV2-wrap .btn-group>:not(.btn-check:first-child)+.btn{
  border-radius: 8px;
  background: #E2EEFF;
  border: none;
  color:rgba(0, 0, 0, 0.4);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  border-radius: 0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border:none;
}

.filtersV2-wrap .btn-check:checked+.btn, 
.filtersV2-wrap .btn.active, 
.filtersV2-wrap .btn.show, 
.filtersV2-wrap .btn:first-child:active, 
.filtersV2-wrap :not(.btn-check)+.btn:active {
  background:  #fff !important;
  color:#053F98 !important;
  font-weight: 600 !important;
  border-bottom: solid 2px #053F98 !important;
}
.back-to-threads {
  cursor: pointer;
  display: inline-block;
  color:#000;
  text-decoration: none;
}
.back-to-threads:hover {
  color:#000;
  opacity: 0.8;
}
.back-to-threads:before {
  content: url(./images/back_btn_2.svg);
  vertical-align: -5px;
  margin-right:3px;
}

.page-pagination {
  margin-bottom: 30px;
  text-align: center;
}

.page-pagination button {
  background:#E83D56 url(./images/carat.svg) center center no-repeat;
  border-radius: 4px;
  border: 1px solid #DFE3E8;
  width:32px;
  height:32px;
  display: inline-block;
  margin: 0 3px 10px;
}
.page-pagination button[disabled] {
  background-color: #919EAB;
}
.page-pagination .prev-button {
  transform: rotate(180deg);
}
.page-pagination span {
  border-radius: 4px;
  border: 1px solid #DFE3E8;
  width:32px;
  height:32px;
  background-color: #fff;
  display: inline-block;
  text-align: center;
  line-height: 32px;
  font-size: 14px;
  color:#212B36;
  font-weight: 700;
  cursor: pointer;
  margin: 0 3px 10px;
}
.page-pagination span.active {
  color:#E83D56;
}
.page-pagination > * {
  vertical-align: middle;
}

.new-filters {
  margin-top:20px;
}
.result-message {
  /* margin-bottom: 20px; */
}
.filtersV2-wrap {
  display: flex;
  justify-content: space-between;
  align-items: end;
  
}
.filters-open-btn {
  color: #053F98;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.21);
  background: #FFF;
  text-align: center;
  padding: 10px 15px 3px;
  
}
.filters-open-btn:after {
  content:url(./images/filters_2.svg);
  vertical-align: -7px;
  margin-left: 2px;
}
.filtersV2-multiselect-popup {
  border-radius: 5px;
  background: #FFF;
  padding: 30px;
  position: relative;
}

.filtersV2-multiselect-popup.popup-visible {
  display: block;
}

.filtersV2-multiselect-popup.popup-hidden {
  display: none;
}
.filtersV2-multiselect-popup:after {
  content: "";
  display: block;
  width: calc(100% - 60px);
  height:1px;
  position: absolute;
  left: 30px;
  bottom:0;
  background-color: #ccc;
}
.filtersV2-multiselect-popup ul {
  list-style: none;
  max-width: 500px;
  width: 100%;
  padding: 0;
}
.filtersV2-multiselect-popup ul li {
  margin-bottom:15px;
}
.sort_by_date {
  width: 100%;
  height: 38px;
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  background: #fff;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color:hsl(0, 0%, 50%);
  padding-left: 8px;
  outline: none !important;
}
.hero-filters-wrap {
  display: flex;
  gap: 10px;
}
.searchWrap {
  position: relative;
  /* margin-top:20px; */
  width: 100%;
}
.hero-search {
  width: 100%;
  height: 45px;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  appearance: none;
  padding-left: 20px;
  padding-right: 40px;
  text-overflow: ellipsis;
  margin-left: 0px;
  outline: none !important;
  background:#fff url(./images/search_v2.svg) right 10px center no-repeat;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.21);
}
.searchLoader {
  position: absolute;
  right: 40px;
  top: 4px;
}
.searchResults {
  position: absolute;
  top: 40px;
  left: 0;
  background: #fff;
  border-radius: 4px;
  height: 400px;
  overflow-y: auto;
  z-index: 300;
  padding-bottom: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
.searchResults ul {
  list-style: none;
  padding:0;
}
.searchResults ul li {
  line-height: 40px;
  border-bottom:solid 1px #ccc;
  cursor: pointer;
  padding: 5px 20px;
  font-size:14px;
  line-height: 18px;
}
.searchResults ul li:hover {
  background-color: #053F98;
  color: #fff;
}

.threads-page {
  position: relative;
}
.threads-page .dash-left,
.threads-page .dash-right {
  transition: width 0.3s ease-in-out;
  position: relative;
  height:calc(100vh - 300px);
  overflow-y: auto;
}
.threads-page .dash-right {
  margin-top:20px;
}

.responseSliders {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  border-radius: 10px;
  background: #F5F5F6;
  padding:20px 10px;
  gap: 50px;
}
.responseSliderItem {
  width: 100%;
  max-width: 100%;
}
.range-slider.range-slider--primary {
  width: 100%;
  max-width: 100%;
}

/********** Range Input Styles **********/
input[type="range"] { 
  margin: auto;
  -webkit-appearance: none;
  position: relative;
  overflow: hidden;
  height: 15px;
  width: 100%;
  cursor: pointer;
  border-radius: 20px; /* iOS */
}

::-webkit-slider-runnable-track {
  background: #ddd;
}

/*
* 1. Set to 0 width and remove border for a slider without a thumb
* 2. Shadow is negative the full width of the input and has a spread 
*    of the width of the input.
*/
::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 15px; /* 1 */
  height: 15px;
  background: #fff;
  box-shadow: -210px 0 0 200px #053F98; /* 2 */
  border: 2px solid #053F98; 
  border-radius: 50%;
}

::-moz-range-track {
  height: 15px;
  background: #ddd;
}

::-moz-range-thumb {
  background: #fff;
  height: 15px;
  width: 15px;
  border: 2px solid #053F98; 
  box-shadow: -210px 0 0 200px #053F98; /* 2 */
  box-sizing: border-box;
  border-radius: 50%;
}

::-ms-fill-lower { 
  background: #053F98;
}

::-ms-thumb { 
  background: #fff;
  border: 2px solid #999;
  height: 15px;
  width: 15px;
  box-sizing: border-box;
  border-radius: 50%;
}

::-ms-ticks-after { 
  display: none; 
}

::-ms-ticks-before { 
  display: none; 
}

::-ms-track { 
  background: #ddd;
  color: transparent;
  height: 15px;
  border: none;
}

::-ms-tooltip { 
  display: none;
}

.range-slider__tooltip__label {
  display: none;
}
.responseSliderItemLabel {
  font-size: 10px !important;
  font-style: normal;
  font-weight: 500;
  display: flex;
  justify-content: space-between ;
  align-items: center;
}
.responseHandleBtn {
  position: absolute;
  left: 0;
  top: 0;
  width: 25px;
  height: 100%;
  display: flex;
  align-items: center;
  display: none;
}
.responseHandleBtn span {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  transform: rotate(180deg);
  
  line-height: 25px;
  cursor: pointer;
 }
 .responseHandleBtn span i {
  color: #053F98;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  display: inline-block;
 }
 .responseHandleBtn span:before {
  content: url(./images/response_btn.svg);
  transform: rotate(180deg);
  display: inline-block;
  margin-bottom: 5px;
 }
 .responseHandleBtn span.res_close_icon:before {
  content: url(./images/res_close_icon.svg);
 }
 .aiContextHeading {
  font-size: 12px;
  font-weight: 600;
  line-height: 17px; /* 141.667% */
  letter-spacing: 0.6px;
 }
 .aiContextMainWrap {
  border-radius: 10px;
  background: #F5F5F6;
  padding:20px 10px 10px;
  margin-bottom:20px;
 }

 .aiContextLinksWrap {
  width:100%;
  max-height: 129px;
  overflow-y: auto;
 }
 .aiContextContentWrap textarea {
  border-radius: 10px;
  border: 0.5px solid #053F98;
  background: #F1F7FF;
  height:78px;
  overflow-y: auto;
  width: 100%;
  margin-top:20px;
  padding: 10px 20px;
  font-size: 12px;
 }
 .aiContextLinks {
  margin-bottom: 5px;
 }
 .aiContextLinks input[type='checkbox'] {
  display: none;
 }
 .aiContextLinks input[type='checkbox'] + label:before {
  content: '';
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-right: 10px;
  border-radius: 4px;
  border: 1px solid #000;
  opacity: 0.5;
  background: #FFF;
}

.aiContextLinks input[type='checkbox']:checked + label:before {
  content: '';
  color: #fff;
  background: #053F98 url(./images/checkbox_2.svg) center center no-repeat;
  text-align: center;
  line-height: 18px;
  opacity: 1;
}

.aiContextLinks label {
  font-size: 14px;
  font-weight: normal;
  color: #333;
  cursor: pointer;
}
.aiContextLinks a {
  font-size: 14px;
  font-weight: normal;
  color: #053F98;
  cursor: pointer;
  vertical-align: 3px;
}
.aiContextLinks label span {
  color: #053F98;
  vertical-align: 3px;
  font-size: 12px;
}

.generateResponseBtn {
  border-radius: 5px;
  border: 0.5px solid #059840;
  background: #DEFFE1;
  color: #059840;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: inline-block;
  padding: 8px 20px 3px;
  cursor: pointer;
}
.generateResponseBtn.disabled {
  background: #f5f5f5;
  color: #000;
  cursor: default;
}
.generateResponseBtn:before {
  content: url(./images/generate.svg);
  display: inline-block;
  vertical-align: -7px;
  margin-right: 2px;
}
.generateResponseBtnWrap {
  text-align: center;
  padding-bottom: 20px;
  border-bottom: solid 1px #E5E5E5;
  margin-bottom:27px;
}
.responseStudioContent {
  display: none;
}
.response-links {
  margin-top:20px;
}
.response-links ul {
  list-style: none;
  padding:0;
}

.question-page {
  min-height: calc(100vh - 120px);
}
.choose-data-wrap {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-bottom: 20px;
  margin-top:20px;
}
.ask-data-choose {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  color: #000000;
  margin-right: 15px;
}
.choose-data-wrap select {
  margin-top:0;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.21);
  background-color: #FFF;
}
.back-to-forum-wrap {
  margin-top:15px;
  margin-bottom:10px;
}

.generate-form {
  border-radius: 5px;
  background: #FFF;
  padding: 40px 20px 30px;
}
.generate-form-wrap textarea {
  border-radius: 10px;
  background: #F5F5F6;
  padding: 20px;
  min-height: 230px;
  width: 100%;
  border:none;
  outline: none;
  font-size:14px;
}
.generate-form-btn-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top:20px;
}
.askQuestionResponse {
  padding-top:14px;
}

.filters-right-btns {
  display: flex;
  align-items: center;
  justify-content: left;
}
.ask-question-btn {
  border-radius: 4px;
  border: 0.5px solid #053F98;
  background: #FFF;
  color:  #053F98 !important;
  text-align: center;
  text-decoration: none;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 100% */
  letter-spacing: 0.3px;
  padding: 7px 12px;
  margin-right: 20px;
  margin-bottom: 4px;
}
.filters-right-btns {
  margin-bottom: 10px;
}
.info-box {
  border-radius: 20px;
  background: #FFF;
  width: 100%;
  min-height: 360px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}
.info-box > * {
  flex-basis: 100%;
  text-align: center;
}
.info-box h3 {
  font-size: 16px;
  font-weight: 700;
  margin-top:16px;
  margin-bottom:18px;
}
.info-box p {
  font-size: 12px;
  font-weight: 300;
  line-height: 17px; /* 141.667% */
  letter-spacing: 0.6px;
}
.ticket-btn {
  margin-left:10px;
}
.insideTableList {
  padding-left: 0px;
  margin-bottom: 0;
  list-style: none;
}
.delete-user-modal-body .css-13cymwt-control,
.delete-user-modal-body .css-t3ipsp-control,
.delete-user-modal-body .css-16xfy0z-control {
  min-height: 48px;
  border-radius: 15px;
  margin-top: 8px;
}
.select-grey .css-16xfy0z-control {
  background: #F5F5F6;
}

.editSourceBtn {
  background: url(./images/edit_icon.svg) no-repeat center center;
  background-size: contain;
  width: 20px;
  height:20px;
  display: inline-block;
}

.disable_btn {
  background: #ccc !important;
  cursor: default !important;
  color:#fff !important;
  border-color: #ccc !important;
  opacity: 0.7;
}

.delete_btn {
  background:url(./images/delete_icon_2.svg) no-repeat center center;
  background-size: contain;
  width: 25px;
  height: 25px;
  display: inline-block;
  margin-left: 7px;
  vertical-align: -2px;
}

.table_action {
  width: 100px;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}


.sidebar-nav {
  width: 100%;
  max-width: 80px;
  background: #053F98 url(./images/sidebar_2.svg) no-repeat center center;
  background-size: cover;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  transition: all 0.3s ease-in-out;
  z-index: 999;
}
.main-content {
  margin-left: 80px;
  transition: margin-left 0.3s ease-in-out;
  min-height: 100vh;
}

.sidebar-active .main-content {
  margin-left: 300px;
}
.sidebar-active .sidebar-nav {
  width: 100%;
  max-width: 300px;
}


.nav-toggle-btn {
  position: absolute;
  top: 80px;
  right: -12px;
  width: 24px;
  height: 24px;
  background:#fff url(./images/nav_btn.svg) no-repeat center center;
  background-size: contain;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  transform: rotate(180deg);
  z-index: 10;
}
.sidebar-active .nav-toggle-btn {
  transform: rotate(0deg);
}
.sidebar-logo {
  display: block;
  border-bottom:solid 1px rgba(255, 255, 255, 0.30);
}
.sidebar-logo-img{
  display: block;
  height: 34px;
  background: url(./images/small_logo.svg) no-repeat center center;
  width: 34px;
  margin:23px 24px 18px;
  transition: all 0.3s ease-in-out;
}

.sidebar-active .sidebar-logo-img {
  background: url(./images/white_logo.svg) no-repeat center center;
  width: 126px;
  margin-left: 20px;
}

.upper-nav {
  position: relative;
  display: block;
  padding: 10px;
  overflow: hidden;
  margin-top:10px;
}
.upper-nav:after {
  background: rgba(255, 255, 255, 0.40);
  content: "";
  display: block;
  height: 1px;
  margin-top: 20px;
  width: calc(100% - 20px);
  margin-left: 10px;
  opacity: 0.4;
}
.side-nav-heading {
  font-size: 11px;
  color:#fff;
  font-weight: 600;
  line-height: 22.4px; /* 203.636% */
  text-transform: uppercase;
  opacity: 0.7;
  width: 0;
  height: 0;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}
.sidebar-active .side-nav-heading {
  width: 100%;
  height: 23px;
}
.upper-nav a {
  border-radius: 10px;
  background: transparent;
  display: block;
  margin-bottom: 5px;
  color: #FFF;
  font-size: 12px;
  font-weight: 400;
  text-decoration: none;
  padding:13px 10px 10px 15px;
  transition: all 0.3s ease-in-out;
  width: 100%;
  overflow: hidden;
  opacity: 1;
  font-family: "Montserrat", sans-serif;
}
.upper-nav a span {
  overflow: hidden;
  width: 0;
  transition: all 0.3s ease-in-out;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  opacity: 0;
  transition-delay: -0.3s;
  font-family: "Montserrat", sans-serif;
}
.upper-nav a:before {
  vertical-align: -1px;
  display: inline-block;
  margin-right: auto;
  margin-left: 7px;
}
.sidebar-active .upper-nav a:before {
  margin-right: 12px;
  margin-left: auto;
}
.upper-nav a.ask-btn:before {
  content:url(./images/nav-icons/ask.svg);
  
}
.responses-btn:before {
  content:url(./images/nav-icons/ask.svg);
}
.upper-nav a.community-btn {
  position: relative;
}
.upper-nav a.community-btn:before {
  content:url(./images/nav-icons/com.svg);
}
.upper-nav a.channel-btn:before {
  content:url(./images/nav-icons/channel.svg);
}
.upper-nav a.ks-btn:before,
.upper-nav a.ksc-btn:before {
  content:url(./images/nav-icons/source.svg);
}
.upper-nav a.response-btn:before {
  content:url(./images/nav-icons/response.svg);
}
.upper-nav a.analysis-btn:before {
  content:url(./images/nav-icons/ksa.svg);
}
.upper-nav a.question-btn:before {
  content:url(./images/nav-icons/question.svg);
}
.upper-nav a.voice-btn:before {
  content:url(./images/nav-icons/voice.svg);
}
.upper-nav a.user-btn:before {
  content:url(./images/nav-icons/user.svg);
}
.upper-nav a.billing-btn:before {
  content:url(./images/nav-icons/billing.svg);
}
.upper-nav a.logout-btn:before {
  content:url(./images/nav-icons/logout.svg);
}
.nav-scroll {
  width: 100%;
  height: calc(100vh - 70px);
  overflow-y: auto;
}


/* .upper-nav a.community-btn:after {
  content:"";
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top:-3px;
  width:10px;
  height:6px;
  background: url(./images/side_down.svg) no-repeat center center;
  transform: rotate(0deg);
  transition: all 0.3s ease-in-out;
  display: none;
} */
.sidebar-active .upper-nav a.community-btn:after {
  display: block;
}
.upper-nav a.community-btn.active:after {
    transform: rotate(180deg);
}
.upper-nav a:hover, .upper-nav a.active {
  background: #4A558A;
  opacity: 1;
  font-weight: 700;
}
.sidebar-active .upper-nav a span {
  width: 200px;
  opacity: 1;
  transition-delay: 0.3s;
}
.sidebar-active .upper-nav a.active span,
.sidebar-active .upper-nav a:hover span {
  font-weight: 700;

}
.sidebar_forum_list {
  list-style: none;
  overflow-y: auto;
  max-height:400px;
  display: none;
}
/* .sidebar_forum_list.open {
  display: block;
} */
.sidebar-active .sidebar_forum_list.open {
  display: block;
}
.sidebar_forum_list li a {
  height:29px;
  padding: 5px 15px;
}

.newtimeandwave {
  font-size: 18px;
  font-weight: 500;
}
.newtimeandwave img {
  width:34px;
}
.hero-text {
  margin-top:20px;
}
.forumName {
  font-size: 16px;
  font-weight: 700;
  line-height: 22.4px;
}
.forumHint {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-top:10px;
}
.hero-text {
  width: 100%;
  color: #000;
}

.ask-loader-wrap {
  display: flex;
  justify-content: left;
  align-items: center;
}
.hero-filter-title {
  margin-bottom: 20px;
}
.hero-filter-title {
  margin-top:20px;
}
.hero-text {
  font-size: 16px;
  font-weight: 600;
  line-height: 22.4px;
  margin-bottom:10px;
}
.hero-p {
  margin-bottom: 0;
}

.userRoleDropDown {
  position: relative;
}

#userRolesModel {
  display: flex;
}
.user-roles-wrap {
  position: relative;
}
.user-roles-wrap ul {
  padding: 0;
  list-style: none;
  margin:0;
  font-size: 14px;
  position: relative;
}
.role-checkbox {
  margin-right: 10px;
  vertical-align: -2px;
}
.normal-role-li li {
  display: inline-block;
  margin-right: 3px;
}
.edit-role-btn {
  color: #053F98;
  display: inline-block;
  background: url(./images/edit_icon.svg) no-repeat center center;
  background-size: contain;
  width: 15px;
  height: 15px;
  vertical-align: -2px;
}
.close-role-btn {
  display: inline-block;
  background: url(./images/list_close.svg) no-repeat center center;
  width: 22px;
  height: 22px;
  background-color: rgba(5, 63, 152, 0.2);
  border-radius: 2px;
}
.update-role-btn {
  display: inline-block;
  background: #053F98 url(./images/list_update.svg) no-repeat center center;
  width: 22px;
  height: 22px;
  border-radius: 2px;
  margin-left: 5px;
}
.table-box td, .table-box th {
  font-size: 14px; 
}
.user-role-dropdown {
  background: url(./images/user_dropdown.svg) no-repeat center right;
  width: 170px;
  padding-right:20px;
  display: block;
  font-size:14px;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
  letter-spacing: 0.2px;
  color:#252733 !important;
  text-decoration: none;
}
.user-role-list {
  border-radius: 10px;
  background:#fff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.10);
  width: 170px;
  position: absolute !important;
  top: calc(100% + 10px);
  left: 0;
  z-index: 20;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.custom-checkbox {
  position: relative;
}
.custom-checkbox label {
  width: 100%;
  cursor: pointer;
  display: inline-block;
  min-height: 28px;
  border-bottom: solid 1px #E5E5E5;
  padding-right: 35px;
  padding-left: 15px;
  line-height: 28px;
  font-size: 14px;
  font-weight: 400;
}

.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.custom-checkbox .checkmark {
  position: absolute;
  top: 5px;
  right: 15px;
  height: 18px;
  width: 18px;
  background-color: #eee;
  border-radius: 4px;
}

.custom-checkbox:hover input ~ .checkmark {
  background-color: #ccc;
}

.custom-checkbox input:checked ~ .checkmark {
  background-color:#053F98;
}

.custom-checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}

.custom-checkbox .checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
.user-role-buttons {
  text-align: right;
  padding-right: 15px;
  margin-top: 10px;
}

.shadowDom {
  width:100%;
  position: relative;
  height: 750px;
}

.chat-window-wrap {
  position: relative;
  width: 100%;
  height: calc(100vh - 210px);
}

.chat-messages-wrap {
  width: 100%;
  height: calc(100vh - 330px);
  background-color: #fff;
  border-radius: 16px;
  overflow-y: auto;
  padding:20px 0;
  position: relative;
}
.chat-messages-wrap.height-one-line {
  height: calc(100vh - 290px);
}
.chat-messages-wrap.height-two-line {
  height: calc(100vh - 310px);
}
.chat-messages-wrap.height-three-line {
  height: calc(100vh - 330px);
}
.chat-input-button-wrap {
  position: absolute;
  bottom:0;
  left: 0;
  width: 100%;
  overflow: hidden;
}
.hidden {
  transition: all 1s ease-in-out;
  opacity: 0;
}
.fadeIn {
  transition: all 1s ease-in-out;
  opacity: 1;
}
.bot-message.hidden {
  transform: translateY(0px);
}
.chat-input-button-wrap textarea {
  width:100%;
  height:78px;
  padding:15px;
  background: rgba(5, 63, 152, 0.10);
  border-radius: 16px;
  border:none !important;
  box-shadow: none !important;
  outline: none !important;
  appearance: none;
  resize: none;
}
.chat-input-button-wrap textarea.height-one-line {
  height: 55px;
}
.chat-input-button-wrap textarea.height-two-line {
  height: 80px;
}
.chat-input-button-wrap textarea.height-three-line {
  height: 100px;
}

.ask-send-btn {
  background: #000 url(./images/generate_icon.svg) no-repeat center center;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 16px;
  margin-top: 10px;
  cursor: pointer;
  position: absolute;
  right:10px;
  bottom:14px;
  border:none !important;
  outline: none !important;
  width:40px;
  height: 40px;
}
.ask-send-btn.disabled,
.ask-send-btn:disabled,
.ask-send-btn[disabled]  {
  background-color: #bebebe;
  cursor: default;
}

.message-sender {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #000;
  margin-bottom: 5px;
}

.chat-loading-wrap {
  width: 100%;
  height: calc(100vh - 430px);
  position: absolute;
  top:0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  z-index: 10;
}
.chat-loading-wrap-app {
  text-align: center;
}
.loading-text{
  flex-grow:0;
  flex-basis:50%;
  margin-top:10px;
  color: #053F98;
}

.chat-loader {
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat-loader .bar {
  display: inline-block;
  width: 3px;
  height: 20px;
  background-color: rgba(5, 63, 152, .5);
  border-radius: 10px;
  animation: scale-up4 1s linear infinite;
}

.chat-loader .bar:nth-child(2) {
  height: 35px;
  margin: 0 5px;
  animation-delay: .25s;
}

.chat-loader .bar:nth-child(3) {
  animation-delay: .5s;
}

.default-question {
  position: absolute;
  bottom:20px;
  left: 20px;
}
.default-question li {
  cursor: pointer;
  border:#ccc 1px solid;
  border-radius: 10px;
  padding:20px;
  font-size: 16px;
  font-weight: 700;
  color:#333;
  list-style: none;
}
.default-question li:hover {
  box-shadow: 0 0 5px #ccc;
}
.feedback-copy {
  width: 100%;
  margin-top:10px;
}
.feedback-copy a {
  margin-right:20px;
  margin-bottom:10px;
  position: relative;
}
.feedback-copy a:nth-child(1) img {
  opacity: 0.6;
}

.copy-text-success {
  padding:2px 5px;
  background: #EBF3FF;
  border-radius: 5px;
  position: absolute;
  text-align: center;
  font-size: 12px;
  line-height: 28px !important;
  color: #154B9F !important;
  top: 28px;
  left: -20px;
  width: 60px;
  display: inline-block;
}

#eptai-chat-messages {
  padding-left: 0;
}
#eptai-chat-messages li.bot-message,
#eptai-chat-messages li.user-message {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px !important;
  padding-right: 20px;
  padding-left:20px;
}

#eptai-chat-messages li.bot-message {
  font-size: 16px; /* Base font size for readability */
  color: #333; /* Standard text color for readability */
  margin-bottom: 2px; /* Space between messages */
  border-radius: 8px; /* Rounded corners for chat bubbles */
}

#eptai-chat-messages li.bot-message a {
  color: #053f98; /* Anchor color as specified */
  text-decoration: none; /* No underline for a cleaner look */
  font-weight: 700;
}

#eptai-chat-messages li.bot-message a:hover {
  text-decoration: underline; /* Underline on hover for clarity */
}

#eptai-chat-messages li.bot-message p, 
#eptai-chat-messages li.bot-message li { /* Styles for paragraphs and list items */
  margin: 4px 0 10px; /* Spacing for readability */
}
#eptai-chat-messages li.bot-message li strong {
  padding-right: 5px;
}

#eptai-chat-messages li.bot-message h1 {
  font-size: 24px; /* Larger font for main headings */
  margin-top: 8px;
  margin-bottom: 6px;
  font-weight: normal;
}

#eptai-chat-messages li.bot-message h2 {
  font-size: 20px; /* Smaller than h1 */
  margin-top: 8px;
  margin-bottom: 6px;
  font-weight: normal;
}

#eptai-chat-messages li.bot-message h3 {
  font-size: 18px; /* Smaller than h2 */
  margin-top: 8px;
  margin-bottom: 6px;
  font-weight: normal;
}

#eptai-chat-messages li.bot-message h4 {
  font-size: 16px; /* Same as base font size, but bold */
  margin-top: 8px;
  margin-bottom: 6px;
  font-weight: bold; /* Making h4 stand out a bit more */
}

#eptai-chat-messages li.bot-message h5 {
  font-size: 14px; /* Smaller than h4 */
  margin-top: 8px;
  margin-bottom: 6px;
  font-weight: bold;
}

#eptai-chat-messages li.bot-message h6 {
  font-size: 12px; /* Smallest of the headings */
  margin-top: 8px;
  margin-bottom: 6px;
  font-weight: bold;
}


#eptai-chat-messages li.bot-message ul, 
#eptai-chat-messages li.bot-message ol {
  margin: 4px 0 4px 20px; /* Indent lists for clarity */
}

#eptai-chat-messages li.bot-message table {
  width: 100%; /* Full width tables */
  border-collapse: collapse; /* Collapse borders for a cleaner look */
  margin-top: 8px; /* Space above the table */
  margin-bottom: 8px; /* Space below the table */
}

#eptai-chat-messages li.bot-message th, 
#eptai-chat-messages li.bot-message td {
  border: 1px solid #ddd; /* Light grey border for cells */
  padding: 8px; /* Padding within cells */
  text-align: left; /* Align text to the left in cells */
}

#eptai-chat-messages li.bot-message th {
  background-color: #f2f2f2; /* Light grey background for header cells */
  font-weight: bold; /* Bold font for header cells */
}

/* Add more specific styles as needed */

#eptai-chat-messages pre {
  white-space: pre-wrap;       /* Since CSS 2.1 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;       /* Internet Explorer 5.5+ */
  overflow-x: auto;
}

#eptai-chat-messages code {
  white-space: pre;
}
#eptai-chat-messages pre, 
#eptai-chat-messages code {
  font-family: 'Fira Code', 'Consolas', 'Monaco', monospace; /* Use a monospaced font for code */
  background-color: #f4f5f7; /* Light grey background */
  color: #333; /* Dark grey text */
}

#eptai-chat-messages pre {
  padding: 1em;
  margin: 0.5em 0;
  overflow: auto; /* Ensures the content is scrollable if it overflows */
  border-radius: 10px; /* Rounded corners */
  border: 1px solid #ddd; /* Light grey border */
}

#eptai-chat-messages code {
  padding: 0.2em 0.4em;
  margin: 0;
  font-size: 0.95em;
  border-radius: 6px;
  border: 1px solid #ececec; /* Very light grey border */
  background-color: #fafafa; /* Very light grey background, slightly different from pre */
}

#eptai-chat-messages pre code {
  padding: 0; /* Override code padding inside pre */
  margin: 0; /* Override code margin inside pre */
  border: none; /* Remove border inside pre */
  background: transparent; /* Remove background inside pre */
  overflow: visible;
  font-size: 1em; /* Keep font size consistent inside pre */
}
.chat-message-wrap {
  max-width: calc(100% - 50px);
}
.reconnection-loading-wrap {
  text-align: center;
}
.reconnecting-text {
  color: #053F98;
  margin-top: 10px;
}

.mobile-logo {
  display: none;
}
#mobile-menu {
  display: none;
}
.mobile-only {
  display: none !important;
}
.main-loader {
  position: fixed;
  width: 100%;
  height: 100%;
  background:#fff;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  top:0;
  left: 0;
}
.ks-form {
  max-width: 500px;
  justify-content: center;
  margin: 0px auto;
}
.ks-form label {
  width: 100%;
  display: block;
}
.ks-form .custom-response-checkbox .checkbox-label {
  margin-left: 30px;
}
.ks-form .input-group {
  margin-bottom: 10px;
}

.ks-footer {
  border-top:solid 1px #E5E5E5;
  display: flex;
  justify-content: end;
  align-items: center;
  margin:15px auto;
  padding-top:20px;
}
.thread-loading-container {
  min-height: calc(100vh - 120px);
}
.accordion-item {
  margin-bottom: 20px;
  filter: drop-shadow(0px 10px 60px rgba(226, 236, 249, 0.50));
  border: none !important;
  border-radius: 10px !important;
}
.accordion-button {
  border-radius: 10px !important;
  box-shadow: none !important;
  outline: none !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  color:#000 !important;
  padding-top:20px;
}
.accordion-button:not(.collapsed) {
  background-color: #fff !important;
  border: none !important;
  box-shadow: none !important;
}
.accordion-body {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top:  0 !important;
}
.accordion-body .table-box {
  margin-top: 0;
}
.items-box-table .table-name-td {
  max-width: 300px;
  min-width: 300px;
  word-break: break-word;
}
.items-box-table .table-url-td {
  max-width: 300px;
  min-width: 300px;
  word-break: break-word;
}
.items-box-table .table-reviews-td {
  min-width: 120px;
}
.table-dislike-img {
  margin-left: 5px;
}

.item-filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding:0px 20px;
}

.boosted {
  border-radius: 50px;
  background: rgba(5, 152, 55, 0.20);
  color: #059837;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 166.667% */
  letter-spacing: 0.1px;
  padding:5px 8px;
}
.boosted:after {
  content:url(./images/boosted_icon.svg);
  margin-left: 5px;
  vertical-align: -3px;
}
.unboosted {
  border-radius: 50px;
  background: rgba(232, 61, 86, 0.20);
  color: #E83D56;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 166.667% */
  letter-spacing: 0.1px;
  padding:5px 8px;
}
.unboosted::after {
  content: url(./images/un_boosted_icon.svg);
  margin-left: 5px;
  vertical-align: -3px;
}
.blocked {
  border-radius: 50px;
  background: rgba(232, 61, 86, 0.20);
  color: #E83D56;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 166.667% */
  letter-spacing: 0.1px;
  padding:5px 8px;
}
.blocked::after {
  content: url(./images/un_boosted_icon.svg);
  margin-left: 5px;
  vertical-align: -2px;
}
.table-action {
  position: relative;
}
.action_btn_dots {
  background:url(./images/action_dots.svg) no-repeat center center;
  width: 40px;
  height: 40px;
  display: inline-block;
}
.action_btns {
  position: absolute;
  right: 0;
  top: 40px;
  background: #FFF;
  border:solid 1px rgba(0, 0, 0, 0.10);
  filter: drop-shadow(0px 10px 60px rgba(0, 0, 0, 0.14));
  border-radius: 10px;
  padding:25px;
  text-align: center;
  width: 290px;
  z-index: 99;
  display: none;
}
.action_btns a {
  display: inline-block;
  padding: 14px 10px 10px;
  text-decoration: none !important;
  width:111px;
  text-align: center;
}
.action_btns .action_btn_unboost {
  background-color: #053F98;
  border-radius: 8px;
  color: #fff;
  margin-right:10px;
  display: none;
}
.action_btns .action_btn_unboost:before {
  content: url(./images/boost_white.svg);
  margin-right:5px;
  vertical-align: -2px;
}
.action_btns .action_btn_boost {
  background-color: #059837;
  border-radius: 8px;
  color: #fff;
  margin-right:10px;
}
.action_btns .action_btn_boost:before {
  content: url(./images/boost_white.svg);
  margin-right:5px;
  vertical-align: -2px;
}
.action_btns .action_btn_block {
  background-color: #E83D56;
  border-radius: 8px;
  color: #fff;
}
.action_btns .action_btn_block:after {
  content:url(./images/block_white.svg);
  margin-left: 5px;
  vertical-align: -1px;
}
.action_btns .action_btn_unblock {
  background-color: #053F98;
  border-radius: 8px;
  color: #fff;
  display: none;
}
.action_btns .action_btn_unblock:after {
  content:url(./images/block_white.svg);
  margin-left: 5px;
  vertical-align: -1px;
}
.action_btns .action_btn_delete {
  background-color: #E83D56;
  border-radius: 8px;
  color: #fff;
  padding: 12px 10px 12px;
}
.action_btns .action_btn_activate {
  background-color: #063f97;
  border-radius: 8px;
  color: #fff;
  margin-right: 8px;
  padding: 12px 10px 12px;
}
.action_btns.show-unboosted .action_btn_unboost {
  display: inline-block;
}
.action_btns.show-unboosted .action_btn_boost {
  display: none;
}
.action_btns.show-unblocked .action_btn_unblock {
  display: inline-block;
}
.action_btns.show-unblocked .action_btn_block {
  display: none;
}
.table-boosted-td {
  min-width: 120px;
}

.item-filters-buttons {
  width: 100%;
  border-bottom:solid 1px #CFCFCF;
  padding-bottom:5px;
}

.item-filters-buttons a {
  font-size: 14px;
  letter-spacing: 0.3px;
  margin-right:30px;
  padding:5px 10px;
  text-decoration: none !important;
  color:#000;
  opacity: 0.5;
  border-bottom: solid 2px transparent;
}
.item-filters-buttons a.active {
  color:#053F98;
  opacity: 1;
  border-color: #053F98;
}

.filters-inputs {
  width: 980px;
  max-width: 100%;
  display: flex;
}

.item-search {
  width:260px;
  height: 55px;
  min-height: 38px;
  padding: 11px 12px;
  border-radius: 10px;
  border: 1px solid rgba(189, 189, 189, 0.40);
  margin-left: 20px;
  margin-right: 10px;
  font-size: 12px;
  background: #fff url(./images/item_search.svg) no-repeat right 10px center;
}
.item-search:focus {
  border: 1px solid #053F98;
}
.item-last-date {
  width:260px;
  height: 48px;
  min-height: 38px;
  padding: 11px 12px;
  border-radius: 10px;
  border: 1px solid rgba(189, 189, 189, 0.40);
  background: #FFF;
  font-size: 12px;
}
.ks-details-status {
  margin:20px 0;
}

.ks_details_box {
  width:100%;
  margin-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}
.details-status {
  border-radius: 6px;
  background: #BEFFC8;
  width: 92px;
  height: 30px;
  font-size: 12px;
  font-weight: 400;
  padding:6px 20px;
  margin-right:20px;
}
.details-status-pending {
  border-radius: 6px;
  background: #ccc;
  width: 92px;
  height: 30px;
  font-size: 12px;
  font-weight: 400;
  padding:6px 20px;
  margin-right:20px;
}
.ingest-date {
  color:#000;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: -0.12px;
}
.ingest-date strong {
  font-weight: 600;
}
.ks_details_box h2 {
  color: #000;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.16px;
  margin-top:30px;
}
.ks_details_box p {
  color: #000;
  font-size: 14px;
  letter-spacing: -0.16px;
}
.url-filters {
  border-radius: 10px;
  background: rgba(92, 101, 149, 0.09);
  max-height: 250px;
  overflow-y: auto;
  padding:20px;
}
.url-filters h2 {
  margin-top:0;
}
.url-filters ul {
  list-style: none;
  padding:0;  
}
.url-filters li {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.14px;
  margin-bottom:10px;
}

.response-question-link {
  width: 100%;
  max-width: 500px;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding:10px 0;
}

.toggle-date-picker {
  background: #fff url(./images/date_range.svg) no-repeat center center;
  border-radius: 10px;
  border: 1px solid rgba(189, 189, 189, 0.40);
  width: 55px;
  height: 55px;
  cursor: pointer;
}
.date-picker-wrap {
  position: relative;
}

.date-picker-box {
  position: absolute;
  right: 0;
  z-index: 90;
}
.response-filters {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-bottom: 20px;
  padding:0px;

}
.response-filters .item-search {
  margin-left: 0;
  width:500px;
  max-width: 100%;
}
.response-filters .filters-inputs {
  width: auto;
}

.res-channel-filter {
  border-radius: 10px;
  border: 1px solid rgba(189, 189, 189, 0.40);
  background: #FFF url(./images/down_icon_forum.svg) no-repeat right 10px center;
  width: 296px;
  margin-right: 10px;
}

.channel-client-id-secret {
  border-radius: 10px;
  background: #FFF;
  filter: drop-shadow(0px 10px 60px rgba(226, 236, 249, 0.50));
  padding:20px;
  margin-top: 20px;
  margin-bottom: 20px;
  overflow: hidden;
}
.channel-client-id-secret h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 22.4px;
  letter-spacing: -0.18px;
  margin-bottom: 20px;

}
.channel-client-id-secret h5 {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.14px;
  margin-right: 5px;
}

.client-input-wrap {
  margin-bottom: 20px;
  position: relative;
  max-width:350px;
  width: 100%;
}

.client-input-wrap .form-control {
  margin-right:10px;
  font-size: 12px;
  padding-right:60px;
  text-overflow: ellipsis;
}

.client-copy-btn {
  background: url(images/copy_new.svg) no-repeat center center !important;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.14px;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  border: none !important;
  outline: none !important;
  opacity: 0.5;
  position: absolute;
  right: 0;
  top: 14px;
}

.show-hide-btn {
  margin-right:10px;
  width: 20px;
  height: 20px;
  border: none !important;
  outline: none !important;
  position: absolute;
  top: 14px;
  right: 25px;
  opacity: 0.5;
}
.show-hide-btn.show {
  background: url(images/hide.svg) no-repeat center center;
}
.show-hide-btn.hide {
  background: url(images/show.svg) no-repeat center center;
}
.tab-content {
  position: relative;
}
.code-tabs-wrap .nav-tabs .nav-link {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.14px;
  color: #000;
  opacity: 0.5;

}
.code-tabs-wrap .nav-tabs .nav-link.active {
  color: #000;
  opacity: 1;
}
.code-tabs-wrap .nav-tabs {
  border-bottom: none;
  margin-bottom: 0 !important;
}
.response-details .code-tabs-wrap  li {
  margin-right: 0;
}
.response-details pre {
  margin-bottom:20px;
}
.response-details h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22.4px;
  letter-spacing: -0.18px;
  margin-bottom: 10px;
}
.script-code-wrap {
  position: relative;
}

.config-option {
  margin-bottom: 20px;
}
.config-option h4 {
  margin-bottom: 5px;
  font-size: 14px;
  color: #333;
}
.config-option p {
  font-size: 12px;
  margin: 0;
  color: #555;
}

.ks-details {
  margin-top:30px;
}

.response-details ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  align-items:start;
  margin-bottom: 20px;
  padding:0px;
  list-style: none;
  padding:0;
}
.response-details li {
  margin-right: 50px;
}
.response-details li h3 {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.14px;
  margin-right: 5px;

}
.response-details li p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.14px;
}



.no-items {
  text-align: center;
}

.answer-feedback-btn {
  background: #053F98 !important;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 16px;
  margin-top: 10px;
  cursor: pointer;
  border:none !important;
  outline: none !important;
}
.answer-feedback-wrap {
  margin-top:10px;
}
.feedback-btn-wrap {
  display: flex;
  justify-content: right;
  align-items: center;
}
.answer-feedback-wrap .form-control {
  min-height: 100px;
  margin-bottom:10px;
}
.answer-feedback-btn-submit {
  background: #053F98 !important;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 16px;
  cursor: pointer;
  border:none !important;
  outline: none !important;
}
.answer-feedback-btn-submit.disabled {
  background-color: #bebebe;
  cursor: default;
}
.answer-feedback-btn-cancel {
  margin-right:10px;
  color:#777777 !important;
}
.answer-feedback-text {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.14px;
  color: #21d809;
  margin-bottom: 10px;
}
.response-details-btn-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.response-details-btn-wrap a {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.14px;
  text-decoration: none;
}
.back-btn {
  font-size: 18px;
  font-weight: 700;
  line-height: 22.4px;
  color:#000 !important;
  text-decoration: none;
  margin:16px 0 20px;
  display: inline-block;
}

.back-btn:before {
  content:url(./images/back_icon.svg);
  vertical-align: -1px;
  margin-right: 10px;
}

.response-detail-page .chat-window-wrap,
.response-detail-page .chat-messages-wrap {
  height: auto !important;
}
.user-placeholder-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #E5E5E5;
  display: inline-block;
  margin-right: 10px;

}
.sources-wrap {
  background: #FFF;
  filter: drop-shadow(0px 10px 60px rgba(226, 236, 249, 0.50));
  border-radius: 18px;
  margin-top:30px;
  margin-bottom:30px;
}
.sources-wrap {
  padding-top:20px;
}
.sources-wrap .filters-inputs {
  width:auto;
}

.sources-wrap .filters-title {
  font-size: 18px;
  font-weight: 700;
  line-height: 22.4px;
}
.filters-input .css-13cymwt-control,
.filters-input .css-t3ipsp-control,
.filters-input .css-16xfy0z-control{
  border-radius: 10px;
  border: 1px solid rgba(189, 189, 189, 0.40) !important;
  background: #FFF;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.14px;
  color: #000;
  padding: 8px 10px;
  margin-right: 10px;
  outline: none !important;
  max-width: 565px;
  width:565px;
  box-shadow: none !important;
  min-height: 55px;
}
.filters-input .css-1p3m7a8-multiValue {
  background: #053F98;
  color: #fff !important;
  border-radius: 10px;
  padding: 2px 5px;
  margin-right: 5px;

}
.filters-input .css-wsp0cs-MultiValueGeneric,
.css-9jq23d {
  color:#fff !important;
}
.css-1nmdiq5-menu .css-d7l1ni-option {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.14px;
  color: #fff;
  background-color: #053F98 ;
}
.css-1nmdiq5-menu .css-10wo9uf-option {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.14px;
  color: #000;
}
.rdrDateRangePickerWrapper {
  box-shadow: rgba(0,0,0,0.2) 0 0 5px;
    overflow: hidden;
    border-radius: 10px;
}

.editable-li {
  position: relative;
  
}
.inline-edit-wrap {
  display: flex;
  justify-content: start;
  align-items: center;
}

.inline-edit-input {
  height: 30px;
  font-size: 12px;
}
.inline-edit-btn {
  background: url(./images/edit_inline_button.svg) no-repeat center center;
  width: 20px;
  height: 20px;
  min-width: 20px;
  display: inline-block;
  cursor: pointer;
  background-size: 100%;
  margin-top: -17px;
  margin-left: 10px;
}

.update-inline-edit {
  background: #053F98 url(./images/checked.svg) no-repeat center center;
  background-size: 10px;
  width: 25px;
  height: 25px;
  border:none !important;
}
.update-inline-edit:hover {
  background-color: #000000;
}
.cancel-inline-edit {
  background: #E83D56;
  background-size: 10px;
  width: 25px;
  height: 25px;
  padding: 0;
  font-size: 12px;
  border:none !important;
}
.inline-edit-btns {
  display: flex;
  justify-content: start;
  align-items: center;
}
.inline-edit-btns button {
  margin-left: 5px;
}
.ks-detail-top {
  border-radius: 10px;
  background: #FFF;
  filter: drop-shadow(0px 10px 60px rgba(226, 236, 249, 0.50));
  padding: 0 20px;
  margin-top:20px;
  margin-bottom: 20px;
  overflow: hidden;
}
.ks-details-desc-box {
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.11);
  background: rgba(217, 217, 217, 0.37);
  margin-bottom: 20px;
  padding: 15px;
}
.ks-details-desc-box h3 {
  font-size: 14px;
  font-weight: 500;
  line-height: 22.4px;
}

.ks-details-desc-box p {
  font-size: 14px;
  font-weight: 400;
  line-height: 22.4px;
  opacity: 0.6;
}
.ks-detail-top .response-details ul {
  margin-bottom: 0;
}


/* Custom CSS for toggle switch */
.confidential-switch .form-check-input {
  display: none; /* Hide the default checkbox */
}

.confidential-switch .toggle-label {
  position: relative;
  display: inline-block;
  width: 50px; /* Width of the toggle switch */
  height: 20px; /* Height of the toggle switch */
  background-color: #ddd; /* Background color when toggle switch is off */
  border-radius: 12px; /* Rounded corners */
  cursor: pointer;
}

.confidential-switch .toggle-label::after {
  content: "";
  position: absolute;
  top: 2px; /* Adjust the vertical position */
  left: 2px; /* Adjust the horizontal position */
  width: 16px; /* Width of the toggle switch handle */
  height: 16px; /* Height of the toggle switch handle */
  background-color: white; /* Color of the toggle switch handle */
  border-radius: 50%; /* Shape of the toggle switch handle */
  transition: all 0.3s ease;
}

.confidential-switch .form-check-input:checked + .toggle-label {
  background-color: #053F98; /* Color when toggle switch is on */
}

.confidential-switch .form-check-input:checked + .toggle-label::after {
  left: calc(100% - 18px); /* Move the toggle switch handle when checked */
}

.table-like-img, .table-dislike-img {
  cursor: pointer;
}
.delete-ks {
  background: url(./images/delete_ks.svg);
  width: 24px;
  height: 24px;
  display: block;
}

.main-error-message {
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.14px;
  margin-top: 10px;
  padding: 20px 20px 0;
  text-align: center;
}
.main-error-message-p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.14px;
  text-align: center;
}

.ghost-box {
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 4px 100px 0px rgba(0, 0, 0, 0.08);
  padding: 40px 20px 60px;
  text-align: center;
  margin-top:20px;
}
.ghost-box img {
  margin-top:30px;
}
.ghost-box h3 {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 22.4px;
  margin:40px auto 20px;
}
.ghost-box p {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.14px;
  margin-top: 10px;
  max-width: 600px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.versions-box-table .table-name-td {
  max-width: 200px;
  min-width: 200px;
  word-break: break-word;

}

.ingest-ks {
  display: inline-block;
  height: 48px;
  padding: 14px 15px;
  border-radius: 8px;
  background: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 166.667% */
  letter-spacing: 0.35px;
  color:#fff !important;
  text-decoration: none !important;
}
.ingest-ks.disabled {
  background: #ccc;
  color:#fff !important;
  cursor: default;
}
.page-not-found {
  border-radius: 5px;
  background: #FFF;
  box-shadow: 0px 4px 100px 0px rgba(0, 0, 0, 0.08);
  padding: 40px 20px 60px;
  text-align: center;
  margin-top:20px;

}
.page-not-found h1 {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  margin-top:30px;
}

.form-select-new .css-13cymwt-control,
.form-select-new .css-t3ipsp-control,
.form-select-new .css-16xfy0z-control {
  height: 46px;
  border: 1px solid rgba(102, 102, 102, 0.35);
  border-radius: 12px;
  transition: border-color 0.5s ease;
  outline: none !important;
  box-shadow: none !important;
}

.connect_btn {
  border-radius: 8px;
  color: #000 !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin-top: 20px;
  border:solid 1px #aeaeae;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 220px;
  margin-bottom: 20px;
}
.connect_btn:hover {
  box-shadow:  0px 4px 10px 0px rgba(0, 0, 0, 0.08);
}

.connect_btn.slack:after {
  background: url(./images/connect_slack.svg) no-repeat center center;
  
}
.connect_btn.hubspot:after {
  background: url(./images/connect_hubspot.svg) no-repeat center center;
}
.connect_btn.zendesk:after {
  background: url(./images/connect_zendesk.svg) no-repeat center center;
}
.connect_btn:after {
  content: "";
  margin-left: 10px;
  vertical-align: -2px;
  background-size: 20px !important;
  width: 20px;
  height: 20px;
  display: inline-block;
}

.dropzone {
  border: 2px dashed #007bff;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 20px;
}

.download-file-page {
  text-align: center;
}
.download-file-page p {
  margin-top:20px;
}
.default-cursor {
  cursor: default !important;
}
.filters-input.filters-input-sm .css-13cymwt-control, 
.filters-input.filters-input-sm .css-t3ipsp-control, 
.filters-input.filters-input-sm .css-16xfy0z-control {
  max-width: 200px;
  width: 200px;
  margin-left: 10px;
}

.thumbs-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
}

.thumb {
  display: flex;
  margin-bottom: 8px;
  margin-right: 8px;
  padding: 4px;
  width: 100%;
}

.thumb-inner {
  display: flex;
  justify-content: left;
  align-items: center;
}

.thumbs-container img {
  display: block;
  width: 30px;
  object-fit: contain;
  margin-right: 8px;
}
.thumbs-container .filename {
  padding-top:2px;
}

.progress-bar {
  width: 100%;  
  height:5px;
  background-color: #ccc;
  border-radius: 5px;
  margin-top: 10px;
}

.progress {
  height: 5px;
  border-radius: 5px;
  background-color: #4caf50;
  width: 0%;
}
.files-uploading-heading {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.14px;
  margin-top: 0px;
}
.page-actions {
  display: flex;
  justify-content: right;
  align-items: center;
  margin-top: 20px;

}
.responses-link {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.14px;
  color: #fff !important;
  background: #053F98;
  text-decoration: none !important;
  display: inline-block;
  padding: 10px 20px;
  border-radius: 8px;
}

.reporting-page h2 {
  font-size: 18px;
  line-height: 22.4px;
  margin-top: 20px;
}
.chart-filter-wrap {
  display: flex;
  justify-content: right;
  align-items: center;
}
.chart-filter-btn-wrap {
  display: flex;
  justify-content: right;
  align-items: center;
}

.chart-filter-btn-wrap button {
  background-color: #f5f5f5;
  border-radius: 8px;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.14px;
  padding: 10px 20px;
  margin-right: 10px;
  border:none !important;
  outline: none !important;

}
.chart-filter-btn-wrap button.active {
  background-color: #053F98;
  color: #fff;
}
.chart-loading {
  margin-right:20px;
}
.reporting-filters {
  margin-top: 20px;
  justify-content: space-between;
}
.reporting-filters .filters-input .css-13cymwt-control, 
.reporting-filters .filters-input .css-t3ipsp-control, 
.reporting-filters .filters-input .css-16xfy0z-control {
  width: 300px;
  max-width: 300px;
}
.switchingForumLoader {
  margin-left: 15px;
}

.questions-wrap {
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 20px;
  padding:15px;
}

.groupedData {
  border: solid 1px #ccc;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 10px;
  background: #f5f5f5;
}
.experiments-page .card-header {
  background-color: #053F98 !important;
  
}
.experiments-page .card-header h4 {
  font-size: 20px !important;
}
.experiments-page .accordion-body {
  padding:15px !important;
}
.experiments-page .accordion-button {
  background-color: #e6e6e6 !important;
  word-break: break-all;
  word-wrap: break-word;
}

.experiments-page h1 {
  margin-top:20px;
  margin-bottom: 20px;
}

@keyframes scale-up4 {
  20% {
    background-color: #053F98;
    transform: scaleY(1.5);
  }

  40% {
    transform: scaleY(1);
  }
}
@media (max-width:1615px) {
  .no-padding {
    padding: 0 !important;
  }
}
@media (max-width:1300px) {
  .threads-count {
    font-size: 12px;
  }
}
@media (max-width:767px) {
  .response-dash-wrap {margin-top: 0;}
  .sidebar-nav {display: none !important;}
  .main-content {margin-left: 0 !important;}
  .dropdown-title-mobile {
      display: none;
  }
  .navbar .profile-avatar {margin-right: 0px;}
  .newtimeandwave {display: none;}
  .mobile-logo {
    display: block;
  }
  .mobile-logo img {
    width: 48px;
  }
  #mobile-menu {
    display: block;
    background: transparent !important;
    color:#000;
    min-width: 200px;
    text-align: left;
  }
  .mobile-menu a {
    color:#000;
    width: 100%;
    display: inline-block;
    padding: 8px 15px;
    text-decoration: none;
  }
  .mobile-menu a.active {
    background: #053F98;
    color: #fff;
  }
  .ask-data-choose {
    display: none;
  }
  .choose-data-wrap {
    justify-content: center;
  }
  .question-page {background-color: #fff;}
  .desktop-only {
    display: none !important;
  }
  .mobile-only {
    display: block !important;
  }
  footer {
    background-color: #fff;
    height: auto;
    padding: 0;
  }
  footer p {
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
    opacity: 0.5;
    color:#000;
    text-align: center;
  }
  .forum-dropdown-mobile {
    width: 100%;
    height: 45px;
    border-radius: 5px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    appearance: none;
    padding-left: 20px;
    padding-right: 40px;
    text-overflow: ellipsis;
    margin-left: 0px;
    outline: none !important;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.21);
    background: #fff url(./images/down_icon_forum.svg) no-repeat right 10px center;
  }
  .filters-open-btn span {
    display: none;
  }
  .filters-open-btn {
    padding:10px 10px 3px;
  }
  .filters-right-btns {
    margin-bottom: 0;
  }
  .threads-page .dash-left, .threads-page .dash-right {
    height: auto;
  }
  .response-wrapper {
    margin-top:0;
  }
  #eptai-chat-messages li.bot-message,
    #eptai-chat-messages li.user-message {
        font-size: 14px; /* Smaller font size for mobile devices */
        padding-left: 0;
    }

    #eptai-chat-messages li.bot-message h1 {
        font-size: 20px; /* Adjust heading sizes accordingly */
    }

    #eptai-chat-messages li.bot-message h2 {
        font-size: 18px;
    }

    #eptai-chat-messages li.bot-message h3 {
        font-size: 16px;
    }

    #eptai-chat-messages li.bot-message h4 {
        font-size: 14px;
    }

    #eptai-chat-messages li.bot-message h5 {
        font-size: 13px;
    }

    #eptai-chat-messages li.bot-message h6 {
        font-size: 12px;
    }
    .default-question li {
      font-size:14px;
      padding: 10px;
    }
    .default-question {
      bottom: 10px;
      left: 0;
    }
    .chat-window-wrap {
      height: calc(100vh - 170px);
    }
    .chat-messages-wrap {
      height: calc(100vh - 290px);
    }
    .chat-messages-wrap.height-one-line {
      height: calc(100vh - 240px);
    }
    .chat-messages-wrap.height-two-line {
      height: calc(100vh - 260px);
    }
    .chat-messages-wrap.height-three-line {
      height: calc(100vh - 280px);
    }
}
@media (max-width:375px){
  .chat-window-wrap {
    height: calc(100dvh - 150px);
  }
  .chat-messages-wrap {
    height: calc(100dvh - 290px);
  }
  .chat-messages-wrap.height-one-line {
    height: calc(100dvh - 240px);
  }
  .chat-messages-wrap.height-two-line {
    height: calc(100dvh - 260px);
  }
  .chat-messages-wrap.height-three-line {
    height: calc(100dvh - 280px);
  }
}