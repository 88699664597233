.footer-container {
  margin-top: auto;
    bottom: 0;
    width: 100%;
    background-color: #525252; /* Set your desired background color */
    color: #fff; /* Set your desired text color */
    padding: 10px; /* Adjust the padding as needed */
  }
  

  