.home-content {
  margin-left: 3rem;
}

.set-table-for-home-section {
  overflow-x: auto;
}

.set-navigation-buttons-background-sm {
  background: #4a558a;
  transition: width 0.3s ease;
}

/* Update styles for sidebar buttons */
.set-navigation-buttons-sm {
  background-color: #111d2c;
}
